// $('#productDataDiv').hide();
//
// $('#productDescription').click(function (){
//     $(this).addClass('active');
//     $('#productData').removeClass('active');
//     $('#productDataDiv').hide(200);
//     setTimeout(function (){
//         $('#productDescriptionDiv').show(200);
//     }, 200);
// });
//
// $('#productData').click(function (){
//     $(this).addClass('active');
//     $('#productDescription').removeClass('active');
//     $('#productDescriptionDiv').hide(200);
//     setTimeout(function (){
//         $('#productDataDiv').show(200);
//     }, 200);
// });



// form elements from product-item.blade.php

window.mouseOver = function(element)
{
    $(element).children().first().children().first().css('opacity', 0);
    $(element).children().first().children().last().css('opacity', 1);
}

window.mouseOut = function(element)
{
    $(element).children().first().children().first().css('opacity', 1);
    $(element).children().first().children().last().css('opacity', 0);
}

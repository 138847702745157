if (window.innerWidth < 1200){
    $('.dropdownNav').each(function(){
        $(this).removeAttr('data-bs-toggle');
    });
}
$('.dropdownNav').each(function(){
    var id = '';
    $(this).mouseenter(function (){
        id = $(this).attr('id');
        $('ul[aria-labelledby="' + id + '"]').css('display', 'block');
        $('ul[aria-labelledby="' + id + '"]').mouseenter(function (){
            $('ul[aria-labelledby="' + id + '"]').css('display', 'block');
        });
        $('ul[aria-labelledby="' + id + '"]').mouseleave(function (){
            $('ul[aria-labelledby="' + id + '"]').css('display', 'none');
        });
    });
    $(this).mouseleave(function (){
        let id = $(this).attr('id');
        $('ul[aria-labelledby="' + id + '"]').css('display', 'none');
    });
});

$(document).ready(function() {
    $("#lightSlider").lightSlider({
        item: 4,
        autoWidth: false,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 10,

        addClass: '',
        mode: "slide",
        useCSS: true,
        cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: 'linear', //'for jquery animation',////

        speed: 500, //ms'
        auto: true,
        loop: true,
        slideEndAnimation: true,
        pause: 8000,

        keyPress: false,
        controls: true,
        prevHtml: '<i class="fas fa-arrow-circle-left"></i>',
        nextHtml: '<i class="fas fa-arrow-circle-right"></i>',

        rtl:false,
        adaptiveHeight:false,

        vertical:false,

        thumbItem:10,
        pager: true,
        gallery: false,
        galleryMargin: 10,
        thumbMargin: 5,
        slideMargin: 30,
        currentPagerPosition: 'middle',

        enableTouch:true,
        enableDrag:false,
        freeMove:true,
        swipeThreshold: 40,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    item: 3,
                }
            },
            {
                breakpoint: 966,
                settings: {
                    item: 2,
                    slideMargin: 20,
                    enableDrag:true,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    item: 1,
                    slideMargin: 10,
                }
            }
        ]
    });
});

window.onload = function () {
    $('.carousel-item .carousel-div-text').addClass('animated-title');
}
